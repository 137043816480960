import React from "react"
import { graphql } from "gatsby"

import { Layout, LinkBox } from "../components"
import SEO from "../components/seo"

const Resume = props => {
  const { data, location } = props
  const siteTitle = data.site.siteMetadata.title
  const resumeHtml = data.markdownRemark.html
  const [edge] = data.allFile.edges
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Projects" />
      <article id="resume">
        <header>
          <h2>Resume</h2>
        </header>
        <iframe title="Resume" src={edge.node.publicURL} />
        <section>
          <LinkBox
            text="Download PDF"
            href={edge.node.publicURL}
            width="200px"
          />
        </section>
        <section id="about" dangerouslySetInnerHTML={{ __html: resumeHtml }} />
      </article>
    </Layout>
  )
}

export default Resume

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allFile(filter: { name: { eq: "resume" }, extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
    markdownRemark(
      frontmatter: { title: { eq: "Resume" }, type: { eq: "resume" } }
    ) {
      html
    }
  }
`
